import * as React from 'react';
import Layout from '../components/layout';
import '../styles/about.css';

const AboutPage = () => {
   return (
      // <Layout pageTitle="About Me">
      <>
         <Layout>
            {/* <p className="subHeader">Living and walking in the love of God.</p>
            <br />
            <div className="bottomSpacer">&nbsp;</div> */}
            <div className="theContainer">
               {/* <p className="subHeader">Christian Ecstaticism</p>
                  <p className="subHeader">Living in the love of God.</p> */}
               <h1 className="theTitle">About Me</h1>
               <p className="theSubtitle">
                  Living and walking in the love of God.
               </p>
               <br />
               Test:
               <br />
               Gatsby_What_File:{process.env.GATSBY_WHAT_FILE}
               <br />
               NODE_ENV:{process.env.NODE_ENV}
               <br />
               NEW INVALIDATED (dev) CLOUDFRONT + MOVED CONTENT + Github Push{' '}
               <br />
               + dynamic + .env dev + local-first (not github)
               <br />
               GATSBY_TARGET_ADDRESS:{process.env.GATSBY_TARGET_ADDRESS}
               <br />
               GATSBY_SITE_ADDRESS:{process.env.GATSBY_SITE_ADDRESS}
               <br />
               GATSBY_TARGET_BUCKET_NAME:{process.env.GATSBY_TARGET_BUCKET_NAME}
               <br />
               TAKE (II)
               <br />
               DEVBUILD AND NEW DEPLOY IN NPM + PACKAGE.JSON
               <br />
               Update redirect with s
               <div>
                  <br />
                  <br />
                  {/* <br /> */}
                  {/* <br /> */}
                  {/* <br />
                  <br /> */}
               </div>
               {/* <br />
               <br />
               <br /> */}
               <div className="peachSpacer">
                  {/* <br /> */}
                  {/* <br /> */}
                  {/* peach spacer */}
                  {/* <br />
                  <br />
                  <br />
                  <br />
                  <br /> */}
                  {/* <br />
                  <br /> */}
               </div>
            </div>
         </Layout>
      </>
   );
};

export default AboutPage;
